.timer {
  display: flex;
  flex-direction: column;
}

.timer.transitioning {
  opacity: 0.8;
}

.timer-started {
  background-color: var(--bgColor-attention-muted);
}

.timer-triggered {
  background-color: var(--bgColor-success-muted);
}

.timer-stopped {
  background-color: var(--bgColor-danger-muted);
}

.timer-replaced {
  background-color: var(--bgColor-muted);
}
